import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Toggle from "react-toggle";
import {
  Card,
  Table,
  Form,
  Button,
  Tabs,
  Tab,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useApolloClient, useQuery } from "@apollo/client";
import ReactSelect from "react-select";
import PublicRoleDetailModal from "./PublicRoleDetailModal";
import DemoProjects from "../DemoProjects";
import CustomPagination, {
  CustomPaginationType,
} from "../Global/CustomPagination";
import { GET_LOCALITIES } from "../../GraphQL/settings/queries";
import {
  GET_PUBLIC_ROLES,
  GET_PARTNER_ROLES,
  GET_PROJECT_ROLES,
  GET_COMMUNITY_ROLES,
  GET_PROJECT_JOB_BOARDS,
} from "../../GraphQL/roles/queries";
import {
  PublicJob,
  PublicRolesData,
  PartnerRolesData,
  CommunityRolesData,
  LocalityData,
  ProjectJobBoards,
} from "../../interfaces";
import { currencyFormat } from "../../utils";
import {
  DesktopContainer,
  MobileContainer,
  MobileCard,
  SearchContainer,
  PageContainer,
  AdvancedSearchContainer,
  AdvancedSearchButton,
  ClearSummarySearch,
  InputSearchGroup,
  ClearSearchButton,
  TabCardContainer,
  ToggleIndustryWrapper,
  TextIndustryWrapper,
} from "../../sharedStyles";
import AdvancedSearch from "../AdvancedSearch";
import useCheckMobile from "../../utils/hooks/useCheckMobile";
import { useFeatureFlagEnabled } from "posthog-js/react";
import config from "../../config";

import guide from "../../assets/role_relay_user_guide.pdf";
interface Props {
  user: any;
  initialActiveTab?: string;
}

const PublicRoles = ({ user, initialActiveTab = "public" }: Props) => {
  const getAdvancedSearchStorage = window?.localStorage?.getItem(
    "advancedSearchRoles"
  );
  const searchStorage = getAdvancedSearchStorage
    ? JSON.parse(getAdvancedSearchStorage ?? "")
    : "";
  const client = useApolloClient();
  const navigate = useNavigate();
  const showAdvancedSearchFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showAdvancedSearch
  );
  const showDemoProjectsFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showDemoProjects
  );

  const [tabKey, setTabKey] = useState(initialActiveTab);
  const [showRoleDetailModal, setShowRoleDetailModal] = useState(false);
  const [activeRole, setActiveRole] = useState(null as any);

  const [publicRoles, setPublicRoles] = useState([] as PublicJob[]);
  const [partnerRoles, setPartnerRoles] = useState([] as PublicJob[]);
  const [communityRoles, setCommunityRoles] = useState([] as PublicJob[]);
  const [projectRoles, setProjectRoles] = useState([] as PublicJob[]);

  /**
   * Logic for deeplinked role
   */
  const [routeRoleId, setRouteRoleId] = useState<number | null>(null);
  const { roleId } = useParams<{
    roleId: string;
  }>();

  useEffect(() => {
    roleId ? setRouteRoleId(Number.parseInt(roleId)) : setRouteRoleId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (routeRoleId !== null && !showRoleDetailModal) {
    if (tabKey === "partner") {
      const role = partnerRoles.find((role) => role.id === routeRoleId);
      if (role) {
        setActiveRole(role);
        setShowRoleDetailModal(true);
      }
    } else if (tabKey === "community") {
      const role = communityRoles.find((role) => role.id === routeRoleId);
      if (role) {
        setActiveRole(role);
        setShowRoleDetailModal(true);
      }
    } else {
      const role = publicRoles.find((role) => role.id === routeRoleId);
      if (role) {
        setActiveRole(role);
        setShowRoleDetailModal(true);
      }
    }

    //TODO: handle if role not found
  }

  const [projectBoards, setProjectBoards] = useState([] as ProjectJobBoards[]);
  const [filterIndustry, setFilterIndustry] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showSummarySearchPublic, setShowSummarySearchPublic] = useState(
    searchStorage?.publicRolesStatus ?? false
  );
  const [summarySearchContentPublic, setSummarySearchContentPublic] =
    useState<string>(searchStorage?.publicRolesSummary ?? "");
  const [filterPublicQuery, setFilterPublicQuery] = useState(
    searchStorage?.publicRolesFilter ?? {}
  );
  const [showSummarySearchPartner, setShowSummarySearchPartner] = useState(
    searchStorage?.partnerRolesStatus ?? false
  );
  const [summarySearchContentPartner, setSummarySearchContentPartner] =
    useState(searchStorage?.partnerRolesSummary ?? "");
  const [filterPartnerQuery, setFilterPartnerQuery] = useState(
    searchStorage?.partnerRolesFilter ?? {}
  );

  const [boardId, setBoardId] = useState<string | null>(null);
  const [boardOptions, setBoardOptions] = useState<any>(null);
  const [hasNextPagePublic, setHasNextPagePublic] = useState(false);
  const [hasNextPagePartner, setHasNextPagePartner] = useState(false);
  const [hasNextPageProject, setHasNextPageProject] = useState(false);
  const [hasNextPageCommunity, setHasNextPageCommunity] = useState(false);

  const publicPaginationRef = useRef<CustomPaginationType>(null);
  const partnerPaginationRef = useRef<CustomPaginationType>(null);
  const projectPaginationRef = useRef<CustomPaginationType>(null);
  const communityPaginationRef = useRef<CustomPaginationType>(null);

  const { width } = useCheckMobile();
  const isMobile = width < 960;

  const { data: dataLocalities } = useQuery<LocalityData>(GET_LOCALITIES);
  const localities = dataLocalities?.localities?.items
    ? [...dataLocalities?.localities?.items]
    : [];
  const locationName = (x: string): string[] => {
    return localities.map((locality) =>
      x === locality?.name ? `${locality?.name} (${locality?.state})` : ""
    );
  };

  const { data: publicRolesData } = useQuery<PublicRolesData>(
    GET_PUBLIC_ROLES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPublicQuery,
        filterByPreferences: filterIndustry,
      },
      onCompleted: (data) => {
        setHasNextPagePublic(data?.publicJobs?.pageInfo?.hasNextPage);
        setPublicRoles(data?.publicJobs?.items);
      },
    }
  );

  const { data: partnerRolesData } = useQuery<PartnerRolesData>(
    GET_PARTNER_ROLES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPartnerQuery,
      },
      onCompleted: (data) => {
        setHasNextPagePartner(data?.partnerJobs?.pageInfo?.hasNextPage);
        setPartnerRoles(data?.partnerJobs?.items);
      },
    }
  );

  const { data: communityRolesData } = useQuery<CommunityRolesData>(
    GET_COMMUNITY_ROLES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPartnerQuery,
      },
      onCompleted: (data) => {
        setHasNextPageCommunity(data?.communityJobs?.pageInfo?.hasNextPage);
        setCommunityRoles(data?.communityJobs?.items);
      },
    }
  );

  const {
    error: errorProjectBoards,
    loading: loadingProjectBoards,
    data: dataProjectBoards,
  } = useQuery(GET_PROJECT_JOB_BOARDS);

  useEffect(() => {
    setProjectBoards(dataProjectBoards?.projectJobBoards?.items);
    if (dataProjectBoards?.projectJobBoards?.items?.length) {
      setBoardOptions(
        dataProjectBoards?.projectJobBoards?.items?.map((brd: any) => ({
          value: brd.id,
          label: brd.title,
        }))
      );

      setBoardId(dataProjectBoards?.projectJobBoards?.items[0].id);
    }
  }, [dataProjectBoards]);

  const runSearch = async (skip = 0, take = 25) => {
    if (tabKey === "public") {
      const getPublicRoles = async () => {
        const { data } = await client.query({
          query: GET_PUBLIC_ROLES,
          variables: {
            search: searchTerm,
            skip,
            take,
            where: filterPublicQuery,
            filterByPreferences: filterIndustry,
          },
        });

        setPublicRoles(data.publicJobs?.items);
        publicPaginationRef.current?.setPagination({
          ...data?.publicJobs?.pageInfo,
          skip,
          take,
          totalCount: data?.publicJobs?.totalCount,
        });
      };
      getPublicRoles();
    } else if (tabKey === "partner") {
      // partner
      const getPartnerRoles = async () => {
        const { data } = await client.query({
          query: GET_PARTNER_ROLES,
          variables: {
            search: searchTerm,
            skip,
            take,
            where: filterPartnerQuery,
          },
        });

        setPartnerRoles(data.partnerJobs?.items);

        if (tabKey === "partner") {
          partnerPaginationRef.current?.setPagination({
            ...data?.partnerJobs?.pageInfo,
            skip,
            take,
            totalCount: data?.partnerJobs?.totalCount,
          });
        } else if (tabKey === "project") {
          projectPaginationRef.current?.setPagination({
            ...data?.partnerJobs?.pageInfo,
            skip,
            take,
            totalCount: data?.partnerJobs?.totalCount,
          });
        }
      };
      getPartnerRoles();
    } else if (tabKey === "community") {
      const getCommunityRoles = async () => {
        const { data } = await client.query({
          query: GET_COMMUNITY_ROLES,
          variables: {
            search: searchTerm,
            skip,
            take,
            where: filterPublicQuery,
            filterByPreferences: filterIndustry,
          },
        });

        setCommunityRoles(data.communityJobs?.items);
        communityPaginationRef.current?.setPagination({
          ...data?.communityJobs?.pageInfo,
          skip,
          take,
          totalCount: data?.communityJobs?.totalCount,
        });
      };
      getCommunityRoles();
    } else if (tabKey === "project" && boardId !== null) {
      const getProjectRoles = async () => {
        const { data } = await client.query({
          query: GET_PROJECT_ROLES,
          variables: {
            projectJobBoardId: boardId,
            skip,
            take,
            where: filterPartnerQuery,
          },
        });

        setProjectRoles(data.projectJobs?.items);

        projectPaginationRef.current?.setPagination({
          ...data?.projectJobs?.pageInfo,
          skip,
          take,
          totalCount: data?.projectJobs?.totalCount,
        });
      };
      getProjectRoles();
    }
  };

  useEffect(() => {
    setSearchTerm("");
    runSearch();
  }, [tabKey]);

  useEffect(() => {
    if (searchTerm === "" && tabKey !== "project") runSearch();
  }, [searchTerm]);

  useEffect(() => {
    runSearch();
  }, [
    publicRolesData?.publicJobs?.items,
    partnerRolesData?.partnerJobs?.items,
    communityRolesData?.communityJobs?.items,
    boardId,
  ]);

  const selectRole = (ind: number) => {
    if (tabKey === "partner") {
      setActiveRole(partnerRoles[ind]);
      navigate(`/partner-roles/${partnerRoles[ind].id}`);
    } else {
      setActiveRole(publicRoles[ind]);
      navigate(`/public-roles/${publicRoles[ind].id}`);
    }
    setShowRoleDetailModal(true);
  };

  const setActiveTab = (tab: string | null) => {
    if (tab) {
      setTabKey(tab);
    }
    setFilterPublicQuery(searchStorage?.publicRolesFilter);
    setFilterPartnerQuery(searchStorage?.partnerRolesFilter);
  };

  const handleOpenAdvancedSearch = () => {
    setShowAdvancedSearch((val) => !val);
  };

  const handleProjectBoardChange = (board: any) => {
    setBoardId(board.value);
  };

  const handleClearSummarySearch = () => {
    if (searchStorage) {
      if (tabKey === "public") {
        searchStorage.publicRolesStatus = false;
        searchStorage.publicRolesSummary = "";
        searchStorage.publicRolesFilter = {};
        setShowSummarySearchPublic(false);
        setFilterPublicQuery({});
      } else if (tabKey === "partner") {
        searchStorage.partnerRolesStatus = false;
        searchStorage.partnerRolesSummary = "";
        searchStorage.partnerRolesFilter = {};
        setShowSummarySearchPartner(false);
        setFilterPartnerQuery({});
      } else if (tabKey === "community") {
        searchStorage.communityRolesStatus = false;
        searchStorage.communityRolesSummary = "";
        searchStorage.communityRolesFilter = {};
        setShowSummarySearchPartner(false);
        setFilterPartnerQuery({});
      }
      window.localStorage.setItem(
        "advancedSearchRoles",
        JSON.stringify(searchStorage)
      );
    }
  };

  return (
    <PageContainer>
      {showDemoProjectsFlag && <DemoProjects />}
      <PublicRoleDetailModal
        show={showRoleDetailModal}
        role={activeRole}
        onHide={() => {
          window.history.replaceState(
            null,
            "",
            tabKey === "public" ? `/public-roles` : `/partner-roles`
          );
          setRouteRoleId(null);
          setShowRoleDetailModal(false);
        }}
      />
      <AdvancedSearchContainer>
        {showAdvancedSearch && (
          <AdvancedSearch
            searchType={tabKey}
            isMobile={isMobile}
            onHandleModal={() => setShowAdvancedSearch(false)}
            onHandleSearch={
              tabKey === "public"
                ? (data: PublicJob[]) => setPublicRoles(data)
                : (data: PublicJob[]) => setPartnerRoles(data)
            }
            onShowSummary={
              tabKey === "public"
                ? (data: boolean) => setShowSummarySearchPublic(data)
                : (data: boolean) => setShowSummarySearchPartner(data)
            }
            onHandleSummaryContent={
              tabKey === "public"
                ? (content: string) => setSummarySearchContentPublic(content)
                : (content: string) => setSummarySearchContentPartner(content)
            }
            onHandleFilterQuery={
              tabKey === "public"
                ? (queryData: any) => setFilterPublicQuery(queryData)
                : (queryData: any) => setFilterPartnerQuery(queryData)
            }
          />
        )}
      </AdvancedSearchContainer>
      <Tabs activeKey={tabKey} onSelect={setActiveTab}>
        <Tab
          eventKey="public"
          title="Public Roles"
          style={!isMobile ? { position: "relative" } : {}}
        >
          <TabCardContainer>
            <Card.Body>
              <SearchContainer screen={!isMobile}>
                <InputSearchGroup screen={!isMobile}>
                  <Form.Control
                    size={isMobile ? "lg" : undefined}
                    type="text"
                    value={searchTerm}
                    placeholder="Search by job title, industry or location"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") runSearch();
                    }}
                  />
                  {searchTerm.length > 0 && (
                    <ClearSearchButton
                      size={25}
                      screen={!isMobile}
                      onClick={() => setSearchTerm("")}
                    />
                  )}
                  <Button onClick={() => runSearch()}>
                    <Search /> Search
                  </Button>
                </InputSearchGroup>
                {showAdvancedSearchFlag && (
                  <AdvancedSearchButton
                    screen={!isMobile}
                    onClick={handleOpenAdvancedSearch}
                  >
                    Advanced Search
                  </AdvancedSearchButton>
                )}
              </SearchContainer>
              {isMobile ? <hr /> : ""}
              {showSummarySearchPublic && (
                <p>
                  {summarySearchContentPublic}
                  <ClearSummarySearch onClick={handleClearSummarySearch}>
                    clear search
                  </ClearSummarySearch>
                </p>
              )}
              <DesktopContainer>
                <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Job Title</th>
                      <th>Industry</th>
                      <th>Location</th>
                      <th>Type</th>
                      <th>Fee Estimate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {publicRoles.map((role, ind) => (
                      <tr key={role.id} onClick={() => selectRole(ind)}>
                        <td>{role.title}</td>
                        <td>{role.industry}</td>
                        <td>{locationName(role?.location?.displayName)}</td>
                        <td>
                          <Badge bg="secondary">
                            {role.workType === "TEMP"
                              ? "CONTRACT/TEMP"
                              : "PERMANENT"}
                          </Badge>
                        </td>
                        {role.workType === "PERMANENT" ? (
                          <td>
                            {currencyFormat(role.feeMin)} -{" "}
                            {currencyFormat(role.feeMax)} {role?.currency}
                          </td>
                        ) : (
                          <td>{currencyFormat(role.feeMin)} per hr</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </DesktopContainer>
              <MobileContainer>
                <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper>
                {publicRoles.map((role, ind) => (
                  <MobileCard key={role.id} onClick={() => selectRole(ind)}>
                    <div>
                      <h4>{role.title}</h4>
                      <strong>Industry:</strong> {role.industry}
                      <br />
                      <strong>Location:</strong>{" "}
                      {locationName(role?.location?.displayName)}
                      <br />
                    </div>
                    <div>
                      <Badge bg="secondary">
                        {role.workType === "TEMP"
                          ? "CONTRACT/TEMP"
                          : "PERMANENT"}
                      </Badge>
                      {role.workType === "PERMANENT" ? (
                        <div>
                          {currencyFormat(role.feeMin)} -{" "}
                          {currencyFormat(role.feeMax)}
                        </div>
                      ) : (
                        <div>{currencyFormat(role.feeMin)} per hr</div>
                      )}
                    </div>
                  </MobileCard>
                ))}
              </MobileContainer>
              {hasNextPagePublic && (
                <CustomPagination
                  ref={publicPaginationRef}
                  onPaginationChange={(args) => runSearch(args.skip, args.take)}
                />
              )}
              <p>
                <a href={guide} target="_blank" rel="noreferrer">
                  Click here to view the User Guide.
                </a>
              </p>
            </Card.Body>
          </TabCardContainer>
        </Tab>
        {partnerRoles.length && (
          <Tab
            eventKey="partner"
            title="Partner Roles"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                <SearchContainer screen={!isMobile}>
                  <InputSearchGroup screen={!isMobile}>
                    <Form.Control
                      size={isMobile ? "lg" : undefined}
                      type="text"
                      value={searchTerm}
                      placeholder="Search by job title, industry or location.."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") runSearch();
                      }}
                    />
                    {searchTerm.length > 0 && (
                      <ClearSearchButton
                        size={25}
                        screen={!isMobile}
                        onClick={() => setSearchTerm("")}
                      />
                    )}
                    <Button onClick={() => runSearch()}>
                      <Search /> Search
                    </Button>
                  </InputSearchGroup>
                  {showAdvancedSearchFlag && (
                    <AdvancedSearchButton
                      screen={!isMobile}
                      onClick={handleOpenAdvancedSearch}
                    >
                      Advanced Search
                    </AdvancedSearchButton>
                  )}
                </SearchContainer>
                {isMobile ? <hr /> : ""}
                {showSummarySearchPartner && (
                  <p>
                    {summarySearchContentPartner}
                    <ClearSummarySearch onClick={handleClearSummarySearch}>
                      clear search
                    </ClearSummarySearch>
                  </p>
                )}
                <DesktopContainer>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Agency</th>
                        <th>Fee Estimate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partnerRoles.map((role, ind) => (
                        <tr key={role.id} onClick={() => selectRole(ind)}>
                          <td>{role.title}</td>
                          <td>{role.industry}</td>
                          <td>{locationName(role?.location?.displayName)}</td>
                          <td>
                            <Badge bg="secondary">
                              {role.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : "PERMANENT"}
                            </Badge>
                          </td>
                          <td>{role.companyName}</td>
                          <td>
                            {currencyFormat(role.feeMin)} -{" "}
                            {currencyFormat(role.feeMax)} {role?.currency}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  {partnerRoles.map((role, ind) => (
                    <MobileCard key={role.id} onClick={() => selectRole(ind)}>
                      <div>
                        <h4>{role.title}</h4>
                        <strong>Industry:</strong> {role.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(role?.location?.displayName)}
                        <br />
                        <strong>Agency:</strong> {role.companyName}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {role.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        {role.workType === "PERMANENT" ? (
                          <div>
                            {currencyFormat(role.feeMin)} -{" "}
                            {currencyFormat(role.feeMax)}
                          </div>
                        ) : (
                          <div>{currencyFormat(role.feeMin)} per hr</div>
                        )}
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
                {hasNextPagePartner && (
                  <CustomPagination
                    ref={partnerPaginationRef}
                    onPaginationChange={(args) =>
                      runSearch(args.skip, args.take)
                    }
                  />
                )}
                <p>
                  <a href={guide} target="_blank" rel="noreferrer">
                    Click here to view the User Guide.
                  </a>
                </p>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
        {communityRoles.length && (
          <Tab
            eventKey="community"
            title="Community Roles"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                {/* <SearchContainer screen={!isMobile}>
                <InputSearchGroup screen={!isMobile}>
                  <Form.Control
                    size={isMobile ? "lg" : undefined}
                    type="text"
                    value={searchTerm}
                    placeholder="Search by job title, industry or location"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") runSearch();
                    }}
                  />
                  {searchTerm.length > 0 && (
                    <ClearSearchButton
                      size={25}
                      screen={!isMobile}
                      onClick={() => setSearchTerm("")}
                    />
                  )}
                  <Button onClick={() => runSearch()}>
                    <Search /> Search
                  </Button>
                </InputSearchGroup>
                {showAdvancedSearchFlag && (
                  <AdvancedSearchButton
                    screen={!isMobile}
                    onClick={handleOpenAdvancedSearch}
                  >
                    Advanced Search
                  </AdvancedSearchButton>
                )}
              </SearchContainer>
              {isMobile ? <hr /> : ""}
              {showSummarySearchPublic && (
                <p>
                  {summarySearchContentPublic}
                  <ClearSummarySearch onClick={handleClearSummarySearch}>
                    clear search
                  </ClearSummarySearch>
                </p>
              )} */}
                <DesktopContainer>
                  {/* <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper> */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Fee Estimate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {communityRoles.map((role, ind) => (
                        <tr key={role.id} onClick={() => selectRole(ind)}>
                          <td>{role.title}</td>
                          <td>{role.industry}</td>
                          <td>{locationName(role?.location?.displayName)}</td>
                          <td>
                            <Badge bg="secondary">
                              {role.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : "PERMANENT"}
                            </Badge>
                          </td>
                          {role.workType === "PERMANENT" ? (
                            <td>
                              {currencyFormat(role.feeMin)} -{" "}
                              {currencyFormat(role.feeMax)} {role?.currency}
                            </td>
                          ) : (
                            <td>{currencyFormat(role.feeMin)} per hr</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  <ToggleIndustryWrapper screen={!isMobile}>
                    <Toggle
                      checked={filterIndustry}
                      icons={false}
                      onChange={() => setFilterIndustry((x) => !x)}
                    />
                    <TextIndustryWrapper>
                      Filter by my{" "}
                      <Link to={"/settings"} state={{ byIndustry: true }}>
                        industry preferences
                      </Link>
                    </TextIndustryWrapper>
                  </ToggleIndustryWrapper>
                  {communityRoles.map((role, ind) => (
                    <MobileCard key={role.id} onClick={() => selectRole(ind)}>
                      <div>
                        <h4>{role.title}</h4>
                        <strong>Industry:</strong> {role.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(role?.location?.displayName)}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {role.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        {role.workType === "PERMANENT" ? (
                          <div>
                            {currencyFormat(role.feeMin)} -{" "}
                            {currencyFormat(role.feeMax)}
                          </div>
                        ) : (
                          <div>{currencyFormat(role.feeMin)} per hr</div>
                        )}
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
                {hasNextPageCommunity && (
                  <CustomPagination
                    ref={communityPaginationRef}
                    onPaginationChange={(args) =>
                      runSearch(args.skip, args.take)
                    }
                  />
                )}
                <p>
                  <a href={guide} target="_blank" rel="noreferrer">
                    Click here to view the User Guide.
                  </a>
                </p>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
        {projectBoards !== undefined && projectBoards.length > 0 && (
          <Tab
            eventKey="project"
            title="Project Roles"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                <div className="mb-3">
                  {loadingProjectBoards && <span>Loading...</span>}
                  {!loadingProjectBoards &&
                    !errorProjectBoards &&
                    projectBoards?.length && (
                      <ReactSelect
                        options={boardOptions}
                        onChange={handleProjectBoardChange}
                        defaultValue={boardOptions.filter(
                          (option: any) => option.value === boardId
                        )}
                      />
                      // <Dropdown>
                      //   <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      //     Select Projects
                      //   </Dropdown.Toggle>
                      //   <Dropdown.Menu>
                      //     {projectBoards?.map(({id, title}) => (
                      //       <Dropdown.Item
                      //         key={id}
                      //         href="#"
                      //       >
                      //         {title}
                      //       </Dropdown.Item>
                      //     ))}
                      //   </Dropdown.Menu>
                      // </Dropdown>
                    )}
                </div>
                <DesktopContainer>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Agency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectRoles.map((role, ind) => (
                        <tr key={role?.id} onClick={() => selectRole(ind)}>
                          <td>{role?.title}</td>
                          <td>{role?.industry}</td>
                          <td>{locationName(role?.location?.displayName)}</td>
                          <td>
                            <Badge bg="secondary">
                              {role?.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : "PERMANENT"}
                            </Badge>
                          </td>
                          <td>{role?.companyName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  {partnerRoles.map((role, ind) => (
                    <MobileCard key={role?.id} onClick={() => selectRole(ind)}>
                      <div>
                        <h4>{role?.title}</h4>
                        <strong>Industry:</strong> {role?.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(role?.location?.displayName)}
                        <br />
                        <strong>Agency:</strong> {role?.companyName}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {role?.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        {role?.workType === "PERMANENT" ? (
                          <div>
                            {currencyFormat(role?.feeMin)} -{" "}
                            {currencyFormat(role?.feeMax)}
                          </div>
                        ) : (
                          <div>{currencyFormat(role?.feeMin)} per hr</div>
                        )}
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
      </Tabs>
    </PageContainer>
  );
};

export default PublicRoles;
