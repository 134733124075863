import { gql } from "@apollo/client";

export const GET_CANDIDATES = gql`
  query GetCandidates($skip: Int, $take: Int, $order: [CandidateSortInput!]) {
    candidates(skip: $skip, take: $take, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        firstName
        lastName
        summary
        idealJobTitles
        yearsOfExperience
        salaryMin
        salaryMax
        jobTitle
        matches
        industry
        workType
        status
        interviewed
        audience
        matches
        currency
        location {
          displayName
          localityId
        }
        candidateOwners {
          userId
          email
        }
        projectBoards {
          id
          projectId
          title
          description
        }
      }
    }
  }
`;

export const GET_CANDIDATE_BY_ID = gql`
  query GetCandidateById($id: Int!) {
    candidates(id: $id) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        summary
        firstName
        lastName
        workType
        jobTitle
        industry
        currency
        salaryMin
        salaryMax
        yearsOfExperience
        idealJobTitles
        matches
        status
        interviewed
        externalId
        audience
        updatedAt
        candidateOwners {
          userId
          email
        }
        location {
          localityId
          displayName
          countryCode
          state
        }
        projectBoards {
          id
          projectId
          title
          description
        }
        communities {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export const GET_PUBLIC_CANDIDATES = gql`
  query GetPublicCandidates(
    $skip: Int!
    $take: Int!
    $search: String!
    $where: PublicCandidateFilterInput
    $filterByPreferences: Boolean
  ) {
    publicCandidates(
      skip: $skip
      take: $take
      search: $search
      where: $where
      filterByPreferences: $filterByPreferences
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        summary
        industry
        idealJobTitles
        jobTitle
        workType
        salaryMax
        salaryMin
        yearsOfExperience
        interviewed
        status
        audience
        currency
        location {
          localityId
          displayName
        }
        communities {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export const GET_PARTNER_CANDIDATES = gql`
  query GetPartnerCandidates(
    $skip: Int!
    $take: Int!
    $search: String!
    $where: PartnerCandidateFilterInput
  ) {
    partnerCandidates(
      skip: $skip
      take: $take
      search: $search
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        summary
        industry
        idealJobTitles
        jobTitle
        workType
        salaryMax
        salaryMin
        yearsOfExperience
        interviewed
        status
        audience
        companyName
        companyId
        currency
        location {
          localityId
          displayName
        }
        communities {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export const GET_PROJECT_CANDIDATES = gql`
  query GetProjectCandidates(
    $skip: Int!
    $take: Int!
    $projectCandidateBoardId: String!
    $where: ProjectCandidateFilterInput
  ) {
    projectCandidates(
      skip: $skip
      take: $take
      projectCandidateBoardId: $projectCandidateBoardId
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        firstName
        lastName
        externalId
        summary
        industry
        idealJobTitles
        jobTitle
        workType
        salaryMax
        salaryMin
        yearsOfExperience
        interviewed
        status
        audience
        currency
        location {
          localityId
          displayName
        }
        boardStatus
        attachments {
          id
          candidateId
          externalId
          projectId
        }
      }
    }
  }
`;

export const GET_COMMUNITY_CANDIDATES = gql`
  query GetCommunityCandidates(
    $skip: Int!
    $take: Int!
  ) {
    communityCandidates(
      skip: $skip
      take: $take
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        summary
        industry
        idealJobTitles
        jobTitle
        workType
        salaryMax
        salaryMin
        yearsOfExperience
        interviewed
        status
        audience
        currency
        location {
          localityId
          displayName
        }
        communities {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export const GET_MATCHED_CANDIDATE = gql`
  query GetMatchedCandidate($matchId: String!) {
    matchedCandidate(matchId: $matchId) {
      id
      externalId
      summary
      industry
      idealJobTitles
      jobTitle
      workType
      salaryMax
      salaryMin
      yearsOfExperience
      interviewed
      status
      companyId
      companyName
      location {
        localityId
        displayName
      }
    }
  }
`;

export const GET_IMPORTED_CANDIDATES = gql`
  query GetImportedCandidates {
    importedCandidates {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        externalId
        firstName
        lastName
        summary
        idealJobTitles
        yearsOfExperience
        salaryMin
        salaryMax
        jobTitle
        matches
        industry
        workType
        status
        interviewed
        audience
        currency
        updatedAt
        location {
          localityId
          displayName
          countryCode
          state
        }
        candidateOwners {
          userId
          email
        }
      }
    }
  }
`;

export const GET_PROJECT_CANDIDATE_BOARDS = gql`
  query ProjectCandidateBoards {
    projectCandidateBoards {
      totalCount
      items {
        id
        projectId
        title
        description
      }
    }
  }
`;

export const GET_PROJECT_CANDIDATE_DETAILS_FROM_ATS = gql`
  query ProjectCandidateDetailsFromATS(
    $candidateId: String!
    $boardId: String!
  ) {
    projectCandidateDetailsFromATS(
      projectCandidateBoardId: $boardId
      candidateId: $candidateId
    ) {
      email
      firstName
      lastName
      mobile
      phone
    }
  }
`;

export const GET_CANDIDATE_ATTACHMENTS = gql`
  query CandidateAttachments($jobAdderId: String!) {
    candidateAttachments(jobAdderId: $jobAdderId) {
      externalId
      fileName
    }
  }
`;

export const TEMP_GET_CANDIDATE_ATTACHMENTS = gql`
  query TempCandidateAttachments($candidateId: String!) {
    candidateAttachments(id: $candidateId) {
      externalId
      fileName
    }
  }
`;
