import styled from "styled-components";
import config from "../../config";
import { useFeatureFlagEnabled } from "posthog-js/react";
import matchingPolicy from "../../assets/role_relay_matching_policy.pdf";
import terms from "../../assets/role_relay_terms.pdf";
const TERMS_URL =
  "https://platform.rolerelay.com/docs/role_relay_terms_and_conditions.pdf";

const Container = styled.div`
  font-size: 14px;
  margin-top: auto;
  padding: 10px;
  /* height: 100px; */
  text-align: right;
  background-color: white;
  display: flex;
  justify-content: space-between;
`;

const FeedbackButton = styled.a`
  cursor: pointer;
`;

const Footer = () => {
  const showSurveyFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showFeedbackSurvey
  );

  return (
    <Container>
      <div>&copy; 2025 Role Relay</div>
      <div> 
      <a href={terms} target="_blank" rel="noreferrer">
        Terms and conditions
      </a>{" "}
      |{" "}
      <a href="/privacy" target="_blank" rel="noreferrer">
        Privacy policy
      </a>{" "}
      |{" "}
      <a href="/dispute" target="_blank" rel="noreferrer">
        Dispute resolution
      </a>{" "}
      |{" "}
      <a href={matchingPolicy} target="_blank" rel="noreferrer">
        Matching policy
      </a>{" "}
      {showSurveyFlag && (
        <>
          |{" "}
          <FeedbackButton className="load-feedback-role-relay">
            Feedback
          </FeedbackButton>
        </>
      )}
      </div>
    </Container>
  );
};

export default Footer;
