import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Toggle from "react-toggle";
import {
  Card,
  Table,
  Form,
  Button,
  Tabs,
  Tab,
  Badge,
  Dropdown,
} from "react-bootstrap";
import ReactSelect from "react-select";
import { useApolloClient, useQuery } from "@apollo/client";
import { Search } from "react-bootstrap-icons";
import PublicCandidateDetailModal from "./PublicCandidateDetailModal";
import {
  PublicCandidate,
  PublicCandidatesData,
  PartnerCandidatesData,
  ProjectCandidate,
  ProjectCandidatesData,
  CommunityCandidatesData,
  LocalityData,
  ProjectCandidateBoards,
} from "../../interfaces";
import CustomPagination, {
  CustomPaginationType,
} from "../Global/CustomPagination";
import { GET_LOCALITIES } from "../../GraphQL/settings/queries";
import {
  GET_PUBLIC_CANDIDATES,
  GET_PARTNER_CANDIDATES,
  GET_PROJECT_CANDIDATES,
  GET_COMMUNITY_CANDIDATES,
  GET_PROJECT_CANDIDATE_BOARDS,
  GET_CANDIDATE_BY_ID,
} from "../../GraphQL/candidates/queries";
import {
  DesktopContainer,
  MobileContainer,
  MobileCard,
  SearchContainer,
  PageContainer,
  AdvancedSearchContainer,
  AdvancedSearchButton,
  ClearSummarySearch,
  InputSearchGroup,
  ClearSearchButton,
  TabCardContainer,
  ToggleIndustryWrapper,
  TextIndustryWrapper,
} from "../../sharedStyles";
import AdvancedSearch from "../AdvancedSearch";
import useCheckMobile from "../../utils/hooks/useCheckMobile";
import { useFeatureFlagEnabled } from "posthog-js/react";
import config from "../../config";

import guide from "../../assets/role_relay_user_guide.pdf";
import DemoProjects from "../DemoProjects";

interface Props {
  user: any;
  initialActiveTab?: string;
}

const PublicCandidates = ({ user, initialActiveTab = "public" }: Props) => {
  const getAdvancedSearchStorage = window?.localStorage?.getItem(
    "advancedSearchCandidates"
  );
  const searchStorage = getAdvancedSearchStorage
    ? JSON.parse(getAdvancedSearchStorage ?? "")
    : "";
  const client = useApolloClient();
  const navigate = useNavigate();

  const showAdvancedSearchFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showAdvancedSearch
  );

  const showDemoProjectsFlag = useFeatureFlagEnabled(
    config?.postHog?.featureFlags?.showDemoProjects
  );

  const [tabKey, setTabKey] = useState(initialActiveTab);
  const [showCandidateDetailModal, setShowCandidateDetailModal] =
    useState(false);
  const [activeCandidate, setActiveCandidate] = useState(null as any);

  const [publicCandidates, setPublicCandidates] = useState(
    [] as PublicCandidate[]
  );
  const [partnerCandidates, setPartnerCandidates] = useState(
    [] as PublicCandidate[]
  );
  const [projectCandidates, setProjectCandidates] = useState(
    [] as ProjectCandidate[]
  );
  const [communityCandidates, setCommunityCandidates] = useState(
    [] as PublicCandidate[]
  );

  /**
   * Logic for deeplinked candidate
   */
  const [routeCandidateId, setRouteCandidateId] = useState<number | null>(null);
  const { candidateId } = useParams<{
    candidateId: string;
  }>();

  useEffect(() => {
    candidateId
      ? setRouteCandidateId(Number.parseInt(candidateId))
      : setRouteCandidateId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (routeCandidateId !== null && !showCandidateDetailModal) {
    if (tabKey === "partner" || tabKey === "project") {
      const candidate = partnerCandidates.find(
        (candidate) => candidate.id === routeCandidateId
      );
      if (candidate) {
        setActiveCandidate(candidate);
        setShowCandidateDetailModal(true);
      }
    } else {
      // const candidate = publicCandidates.find(
      //   (candidate) => candidate.id === routeCandidateId
      // );
      client
        .query({
          query: GET_CANDIDATE_BY_ID,
          variables: { id: routeCandidateId },
        })
        .then((res) => {
          if (res.data?.publicCandidate) {
            setActiveCandidate(res.data.publicCandidate);
            setShowCandidateDetailModal(true);
          }
        });
    }

    //TODO: handle if role not found
  }

  const [projectBoards, setProjectBoards] = useState(
    [] as ProjectCandidateBoards[]
  );
  const [filterIndustry, setFilterIndustry] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showSummarySearchPublic, setShowSummarySearchPublic] = useState(
    searchStorage?.publicCandidatesStatus ?? false
  );
  const [summarySearchContentPublic, setSummarySearchContentPublic] =
    useState<string>(searchStorage?.publicCandidatesSummary ?? "");
  const [filterPublicQuery, setFilterPublicQuery] = useState(
    searchStorage?.publicCandidatesFilter ?? {}
  );
  const [showSummarySearchPartner, setShowSummarySearchPartner] = useState(
    searchStorage?.partnerCandidatesStatus ?? false
  );
  const [summarySearchContentPartner, setSummarySearchContentPartner] =
    useState(searchStorage?.partnerCandidatesSummary ?? "");
  const [filterPartnerQuery, setFilterPartnerQuery] = useState(
    searchStorage?.partnerCandidatesFilter ?? {}
  );

  const [boardId, setBoardId] = useState<string | null>(null);
  const [boardOptions, setBoardOptions] = useState<any>(null);

  const [hasNextPagePublic, setHasNextPagePublic] = useState(false);
  const [hasNextPagePartner, setHasNextPagePartner] = useState(false);
  const [hasNextPageProject, setHasNextPageProject] = useState(false);
  const [hasNextPageCommunity, setHasNextPageCommunity] = useState(false);

  const publicPaginationRef = useRef<CustomPaginationType>(null);
  const partnerPaginationRef = useRef<CustomPaginationType>(null);
  const projectPaginationRef = useRef<CustomPaginationType>(null);

  const { width } = useCheckMobile();
  const isMobile = width < 960;

  const { data: dataLocalities } = useQuery<LocalityData>(GET_LOCALITIES);
  const localities = dataLocalities?.localities?.items
    ? [...dataLocalities?.localities?.items]
    : [];
  const locationName = (x: string): string[] => {
    return localities.map((locality) =>
      x === locality?.name ? `${locality?.name} (${locality?.state})` : ""
    );
  };

  const { data: publicCandidatesData } = useQuery<PublicCandidatesData>(
    GET_PUBLIC_CANDIDATES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPublicQuery,
        filterByPreferences: filterIndustry,
      },
      onCompleted: (data) => {
        setHasNextPagePublic(data?.publicCandidates?.pageInfo?.hasNextPage);
        setPublicCandidates(data?.publicCandidates?.items);
      },
    }
  );

  const { data: partnerCandidatesData } = useQuery<PartnerCandidatesData>(
    GET_PARTNER_CANDIDATES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPartnerQuery,
      },
      onCompleted: (data) => {
        setHasNextPagePartner(data?.partnerCandidates?.pageInfo?.hasNextPage);
        setPartnerCandidates(data?.partnerCandidates?.items);
      },
    }
  );

  const { data: communityCandidatesData } = useQuery<CommunityCandidatesData>(
    GET_COMMUNITY_CANDIDATES,
    {
      variables: {
        search: searchTerm,
        skip: 0,
        take: 25,
        where: filterPartnerQuery,
      },
      onCompleted: (data) => {
        setHasNextPageCommunity(
          data?.communityCandidates?.pageInfo?.hasNextPage
        );
        setCommunityCandidates(data?.communityCandidates?.items);
      },
    }
  );

  const {
    error: errorProjectBoards,
    loading: loadingProjectBoards,
    data: dataProjectBoards,
  } = useQuery(GET_PROJECT_CANDIDATE_BOARDS);

  useEffect(() => {
    setProjectBoards(dataProjectBoards?.projectCandidateBoards?.items);
    if (dataProjectBoards?.projectCandidateBoards?.items?.length) {
      setBoardOptions(
        dataProjectBoards?.projectCandidateBoards?.items?.map((brd: any) => ({
          value: brd.id,
          label: brd.title,
        }))
      );
      setBoardId(dataProjectBoards?.projectCandidateBoards?.items[0].id);
    }
  }, [dataProjectBoards]);

  const runSearch = (skip = 0, take = 25) => {
    console.log("running search with tab ", tabKey);
    if (tabKey === "public") {
      const getPublicCandidates = async () => {
        const { data } = await client.query({
          query: GET_PUBLIC_CANDIDATES,
          variables: {
            search: searchTerm,
            skip,
            take,
            where: filterPublicQuery,
            filterByPreferences: filterIndustry,
          },
        });
        setPublicCandidates(data.publicCandidates?.items);
        publicPaginationRef.current?.setPagination({
          ...data?.publicCandidates?.pageInfo,
          skip,
          take,
          totalCount: data?.publicCandidates?.totalCount,
        });
      };
      getPublicCandidates();
    } else if (tabKey === "partner") {
      // partner
      const getPartnerCandidates = async () => {
        const { data } = await client.query({
          query: GET_PARTNER_CANDIDATES,
          variables: {
            search: searchTerm,
            skip,
            take,
            where: filterPartnerQuery,
          },
        });

        setPartnerCandidates(data.partnerCandidates?.items);

        partnerPaginationRef.current?.setPagination({
          ...data?.partnerCandidates?.pageInfo,
          skip,
          take,
          totalCount: data?.partnerCandidates?.totalCount,
        });
      };
      getPartnerCandidates();
    } else if (tabKey === "project" && boardId !== null) {
      // project
      console.group("getting project candidates");
      const getProjectCandidates = async () => {
        const { data } = await client.query({
          query: GET_PROJECT_CANDIDATES,
          variables: {
            projectCandidateBoardId: boardId,
            skip,
            take,
            where: filterPartnerQuery,
          },
        });

        setProjectCandidates(data.projectCandidates?.items);

        projectPaginationRef.current?.setPagination({
          ...data?.projectCandidates?.pageInfo,
          skip,
          take,
          totalCount: data?.projectCandidates?.totalCount,
        });
      };
      getProjectCandidates();
    }
  };

  useEffect(() => {
    setSearchTerm("");
    runSearch();
  }, [tabKey]);

  useEffect(() => {
    if (searchTerm === "" && tabKey !== "project") runSearch();
  }, [searchTerm]);

  useEffect(() => {
    runSearch();
  }, [
    publicCandidatesData?.publicCandidates?.items,
    partnerCandidatesData?.partnerCandidates?.items,
    boardId,
  ]);

  const selectCandidate = (ind: number) => {
    if (tabKey === "partner") {
      setActiveCandidate(partnerCandidates[ind]);
      navigate(`/partner-candidates/${partnerCandidates[ind].id}`);
    } else if (tabKey === "project") {
      setActiveCandidate(projectCandidates[ind]);
      navigate(`/project-candidates/${projectCandidates[ind].id}`);
    } else {
      setActiveCandidate(publicCandidates[ind]);
      navigate(`/public-candidates/${publicCandidates[ind].id}`);
    }
    setShowCandidateDetailModal(true);
  };

  const setActiveTab = (tab: string | null) => {
    if (tab) {
      setTabKey(tab);
    }
    setFilterPublicQuery(searchStorage?.publicCandidatesFilter);
    setFilterPartnerQuery(searchStorage?.partnerCandidatesFilter);
  };

  const handleOpenAdvancedSearch = () => {
    setShowAdvancedSearch((val) => !val);
  };

  const handleProjectBoardChange = (board: any) => {
    console.log("setting board id", board.value);
    setBoardId(board.value);
  };

  const handleClearSummarySearch = () => {
    if (searchStorage) {
      if (tabKey === "public") {
        searchStorage.publicCandidatesStatus = false;
        searchStorage.publicCandidatesSummary = "";
        searchStorage.publicCandidatesFilter = {};
        setShowSummarySearchPublic(false);
        setFilterPublicQuery({});
      } else {
        searchStorage.partnerCandidatesStatus = false;
        searchStorage.partnerCandidatesSummary = "";
        searchStorage.partnerCandidatesFilter = {};
        setShowSummarySearchPartner(false);
        setFilterPartnerQuery({});
      }
      window.localStorage.setItem(
        "advancedSearchCandidates",
        JSON.stringify(searchStorage)
      );
    }
  };

  return (
    <PageContainer>
      {showDemoProjectsFlag && <DemoProjects />}
      <PublicCandidateDetailModal
        variantType={tabKey}
        show={showCandidateDetailModal}
        candidate={activeCandidate}
        refetch={runSearch}
        boardId={boardId}
        onHide={() => {
          window.history.replaceState(
            null,
            "",
            tabKey === "public" ? `/public-candidates` : `/partner-candidates`
          );
          setRouteCandidateId(null);

          setShowCandidateDetailModal(false);
        }}
      />
      <AdvancedSearchContainer>
        {showAdvancedSearch && (
          <AdvancedSearch
            searchType={tabKey}
            isMobile={isMobile}
            onHandleModal={() => setShowAdvancedSearch(false)}
            onHandleSearch={
              tabKey === "public"
                ? (data: PublicCandidate[]) => setPublicCandidates(data)
                : (data: PublicCandidate[]) => setPartnerCandidates(data)
            }
            onShowSummary={
              tabKey === "public"
                ? (data: boolean) => setShowSummarySearchPublic(data)
                : (data: boolean) => setShowSummarySearchPartner(data)
            }
            onHandleSummaryContent={
              tabKey === "public"
                ? (content: string) => setSummarySearchContentPublic(content)
                : (content: string) => setSummarySearchContentPartner(content)
            }
            onHandleFilterQuery={
              tabKey === "public"
                ? (queryData: any) => setFilterPublicQuery(queryData)
                : (queryData: any) => setFilterPartnerQuery(queryData)
            }
          />
        )}
      </AdvancedSearchContainer>
      <Tabs activeKey={tabKey} onSelect={setActiveTab}>
        <Tab
          eventKey="public"
          title="Public Candidates"
          style={!isMobile ? { position: "relative" } : {}}
        >
          <TabCardContainer>
            <Card.Body>
              <SearchContainer screen={!isMobile}>
                <InputSearchGroup screen={!isMobile}>
                  <Form.Control
                    size={isMobile ? "lg" : undefined}
                    type="text"
                    value={searchTerm}
                    placeholder="Search by job title, industry or location"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") runSearch();
                    }}
                  />
                  {searchTerm.length > 0 && (
                    <ClearSearchButton
                      size={25}
                      screen={!isMobile}
                      onClick={() => setSearchTerm("")}
                    />
                  )}
                  <Button onClick={() => runSearch()}>
                    <Search /> Search
                  </Button>
                </InputSearchGroup>
                {showAdvancedSearchFlag && (
                  <AdvancedSearchButton
                    screen={!isMobile}
                    onClick={handleOpenAdvancedSearch}
                  >
                    Advanced Search
                  </AdvancedSearchButton>
                )}
              </SearchContainer>
              {isMobile ? <hr /> : ""}
              {showSummarySearchPublic && (
                <p>
                  {summarySearchContentPublic}
                  <ClearSummarySearch onClick={handleClearSummarySearch}>
                    clear search
                  </ClearSummarySearch>
                </p>
              )}
              <DesktopContainer>
                <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Job Titles</th>
                      <th>Industry</th>
                      <th>Location</th>
                      <th>Type</th>
                      <th>Exp. (yrs)</th>
                    </tr>
                  </thead>

                  <tbody>
                    {publicCandidates.map((candidate, ind) => {
                      return (
                        <tr
                          key={candidate.id}
                          onClick={() => selectCandidate(ind)}
                        >
                          <td>{candidate.idealJobTitles}</td>
                          <td>{candidate.industry}</td>
                          <td>
                            {locationName(candidate?.location?.displayName)}
                          </td>
                          <td>
                            <Badge bg="secondary">
                              {candidate.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : candidate.workType}
                            </Badge>
                          </td>
                          <td>{candidate.yearsOfExperience}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </DesktopContainer>
              <MobileContainer>
                <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper>
                {publicCandidates.map((candidate, ind) => (
                  <MobileCard
                    key={candidate.id}
                    onClick={() => selectCandidate(ind)}
                  >
                    <div>
                      <h4>{candidate.idealJobTitles}</h4>
                      <strong>Industry:</strong> {candidate.industry}
                      <br />
                      <strong>Location:</strong>{" "}
                      {locationName(candidate?.location?.displayName)}
                      <br />
                    </div>
                    <div>
                      <Badge bg="secondary">
                        {candidate.workType === "TEMP"
                          ? "CONTRACT/TEMP"
                          : "PERMANENT"}
                      </Badge>
                      <div>Exp: {candidate.yearsOfExperience} yrs</div>
                    </div>
                  </MobileCard>
                ))}
              </MobileContainer>
              {hasNextPagePublic && (
                <CustomPagination
                  ref={publicPaginationRef}
                  onPaginationChange={(args) => runSearch(args.skip, args.take)}
                />
              )}
              <p>
                <a href={guide} target="_blank" rel="noreferrer">
                  Click here to view the User Guide.
                </a>
              </p>
            </Card.Body>
          </TabCardContainer>
        </Tab>
        {partnerCandidates.length && (
          <Tab
            eventKey="partner"
            title="Partner Candidates"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                <SearchContainer screen={!isMobile}>
                  <InputSearchGroup screen={!isMobile}>
                    <Form.Control
                      size={isMobile ? "lg" : undefined}
                      type="text"
                      value={searchTerm}
                      placeholder="Search by job title, industry or location"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") runSearch();
                      }}
                    />
                    {searchTerm.length > 0 && (
                      <ClearSearchButton
                        size={25}
                        screen={!isMobile}
                        onClick={() => setSearchTerm("")}
                      />
                    )}
                    <Button onClick={() => runSearch()}>
                      <Search /> Search
                    </Button>
                  </InputSearchGroup>
                  {showAdvancedSearchFlag && (
                    <AdvancedSearchButton
                      screen={!isMobile}
                      onClick={handleOpenAdvancedSearch}
                    >
                      Advanced Search
                    </AdvancedSearchButton>
                  )}
                </SearchContainer>
                {isMobile ? <hr /> : ""}
                {showSummarySearchPartner && (
                  <p>
                    {summarySearchContentPartner}
                    <ClearSummarySearch onClick={handleClearSummarySearch}>
                      clear search
                    </ClearSummarySearch>
                  </p>
                )}
                <DesktopContainer>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Titles</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Agency</th>
                        <th>Years Experience</th>
                      </tr>
                    </thead>
                    <tbody>
                      {partnerCandidates.map((candidate, ind) => (
                        <tr
                          key={candidate.id}
                          onClick={() => selectCandidate(ind)}
                        >
                          <td>{candidate.idealJobTitles}</td>
                          <td>{candidate.industry}</td>
                          <td>
                            {locationName(candidate?.location?.displayName)}
                          </td>
                          <td>
                            <Badge bg="secondary">
                              {candidate.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : candidate.workType}
                            </Badge>
                          </td>
                          <td>{candidate.companyName}</td>
                          <td>{candidate.yearsOfExperience}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  {partnerCandidates.map((candidate, ind) => (
                    <MobileCard
                      key={candidate.id}
                      onClick={() => selectCandidate(ind)}
                    >
                      <div>
                        <h4>{candidate.idealJobTitles}</h4>
                        <strong>Industry:</strong> {candidate.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(candidate?.location?.displayName)}
                        <br />
                        <strong>Agency:</strong> {candidate.companyName}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {candidate.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        <div>Exp: {candidate.yearsOfExperience} yrs</div>
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
                {hasNextPagePartner && (
                  <CustomPagination
                    ref={partnerPaginationRef}
                    onPaginationChange={(args) =>
                      runSearch(args.skip, args.take)
                    }
                  />
                )}
                <p>
                  <a href={guide} target="_blank" rel="noreferrer">
                    Click here to view the User Guide.
                  </a>
                </p>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
        {communityCandidates.length && (
          <Tab
            eventKey="community"
            title="Community Candidates"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                {/* <SearchContainer screen={!isMobile}>
                <InputSearchGroup screen={!isMobile}>
                  <Form.Control
                    size={isMobile ? "lg" : undefined}
                    type="text"
                    value={searchTerm}
                    placeholder="Search by job title, industry or location"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") runSearch();
                    }}
                  />
                  {searchTerm.length > 0 && (
                    <ClearSearchButton
                      size={25}
                      screen={!isMobile}
                      onClick={() => setSearchTerm("")}
                    />
                  )}
                  <Button onClick={() => runSearch()}>
                    <Search /> Search
                  </Button>
                </InputSearchGroup>
                {showAdvancedSearchFlag && (
                  <AdvancedSearchButton
                    screen={!isMobile}
                    onClick={handleOpenAdvancedSearch}
                  >
                    Advanced Search
                  </AdvancedSearchButton>
                )}
              </SearchContainer>
              {isMobile ? <hr /> : ""} 
              {showSummarySearchPublic && (
                <p>
                  {summarySearchContentPublic}
                  <ClearSummarySearch onClick={handleClearSummarySearch}>
                    clear search
                  </ClearSummarySearch>
                </p>
              )}*/}
                <DesktopContainer>
                  {/* <ToggleIndustryWrapper screen={!isMobile}>
                  <Toggle
                    checked={filterIndustry}
                    icons={false}
                    onChange={() => setFilterIndustry((x) => !x)}
                  />
                  <TextIndustryWrapper>
                    Filter by my{" "}
                    <Link to={"/settings"} state={{ byIndustry: true }}>
                      industry preferences
                    </Link>
                  </TextIndustryWrapper>
                </ToggleIndustryWrapper> */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Job Titles</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Exp. (yrs)</th>
                      </tr>
                    </thead>

                    <tbody>
                      {communityCandidates.map((candidate, ind) => {
                        return (
                          <tr
                            key={candidate.id}
                            onClick={() => selectCandidate(ind)}
                          >
                            <td>{candidate.idealJobTitles}</td>
                            <td>{candidate.industry}</td>
                            <td>
                              {locationName(candidate?.location?.displayName)}
                            </td>
                            <td>
                              <Badge bg="secondary">
                                {candidate.workType === "TEMP"
                                  ? "CONTRACT/TEMP"
                                  : candidate.workType}
                              </Badge>
                            </td>
                            <td>{candidate.yearsOfExperience}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  <ToggleIndustryWrapper screen={!isMobile}>
                    <Toggle
                      checked={filterIndustry}
                      icons={false}
                      onChange={() => setFilterIndustry((x) => !x)}
                    />
                    <TextIndustryWrapper>
                      Filter by my{" "}
                      <Link to={"/settings"} state={{ byIndustry: true }}>
                        industry preferences
                      </Link>
                    </TextIndustryWrapper>
                  </ToggleIndustryWrapper>
                  {publicCandidates.map((candidate, ind) => (
                    <MobileCard
                      key={candidate.id}
                      onClick={() => selectCandidate(ind)}
                    >
                      <div>
                        <h4>{candidate.idealJobTitles}</h4>
                        <strong>Industry:</strong> {candidate.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(candidate?.location?.displayName)}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {candidate.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        <div>Exp: {candidate.yearsOfExperience} yrs</div>
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
                {hasNextPagePublic && (
                  <CustomPagination
                    ref={publicPaginationRef}
                    onPaginationChange={(args) =>
                      runSearch(args.skip, args.take)
                    }
                  />
                )}
                <p>
                  <a href={guide} target="_blank" rel="noreferrer">
                    Click here to view the User Guide.
                  </a>
                </p>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
        {projectBoards !== undefined && projectBoards.length > 0 && (
          <Tab
            eventKey="project"
            title="Project Candidates"
            style={!isMobile ? { position: "relative" } : {}}
          >
            <TabCardContainer>
              <Card.Body>
                <div className="mb-3">
                  {loadingProjectBoards && <span>Loading...</span>}
                  {!loadingProjectBoards &&
                    !errorProjectBoards &&
                    projectBoards?.length && (
                      <ReactSelect
                        options={boardOptions}
                        onChange={handleProjectBoardChange}
                        defaultValue={boardOptions.filter(
                          (option: any) => option.value === boardId
                        )}
                      />
                      // <Dropdown onChange={handleProjectBoardChange}>
                      //   <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      //     Select Projects
                      //   </Dropdown.Toggle>
                      //   <Dropdown.Menu>
                      //     {projectBoards?.map(({ id, title }) => (
                      //       <Dropdown.Item key={id} href="#">
                      //         {title}
                      //       </Dropdown.Item>
                      //     ))}
                      //   </Dropdown.Menu>
                      // </Dropdown>
                    )}
                </div>
                <DesktopContainer>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Job Title</th>
                        <th>Industry</th>
                        <th>Location</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectCandidates.map((candidate, ind) => (
                        <tr
                          key={candidate?.id}
                          onClick={() => selectCandidate(ind)}
                        >
                          <td>
                            {candidate?.firstName} {candidate.lastName}
                          </td>
                          <td>{candidate?.idealJobTitles}</td>
                          <td>{candidate?.industry}</td>
                          <td>
                            {locationName(candidate?.location?.displayName)}
                          </td>
                          <td>
                            <Badge bg="secondary">
                              {candidate?.workType === "TEMP"
                                ? "CONTRACT/TEMP"
                                : "PERMANENT"}
                            </Badge>
                          </td>
                          <td>{candidate?.boardStatus}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </DesktopContainer>
                <MobileContainer>
                  {projectCandidates.map((candidate, ind) => (
                    <MobileCard
                      key={candidate?.id}
                      onClick={() => selectCandidate(ind)}
                    >
                      <div>
                        <h4>{candidate?.idealJobTitles}</h4>
                        <strong>Industry:</strong> {candidate?.industry}
                        <br />
                        <strong>Location:</strong>{" "}
                        {locationName(candidate?.location?.displayName)}
                        <br />
                        <strong>Agency:</strong> {candidate?.companyName}
                        <br />
                      </div>
                      <div>
                        <Badge bg="secondary">
                          {candidate?.workType === "TEMP"
                            ? "CONTRACT/TEMP"
                            : "PERMANENT"}
                        </Badge>
                        <div>Exp: {candidate?.yearsOfExperience} yrs</div>
                      </div>
                    </MobileCard>
                  ))}
                </MobileContainer>
              </Card.Body>
            </TabCardContainer>
          </Tab>
        )}
      </Tabs>
    </PageContainer>
  );
};

export default PublicCandidates;
